// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-templates-pages-dynamic-page-index-js": () => import("./../../../src/templates/pages/dynamic-page/index.js" /* webpackChunkName: "component---src-templates-pages-dynamic-page-index-js" */)
}

